<template>
  <v-col cols="auto" md="12" class="bg-secondary pa-0 ma-0" style="height: 400px;">
    <p class="text-h4 pt-4 text-center" style="line-height: 1.5">
      <b class="">Mi carrito</b>
    </p>
    <hr class="mx-2" />

    <!-- Mostrar mensaje cuando el carrito está vacío -->
    <div v-if="!tieneProductos" style="height: 320px;" class="carritoMsg__div px-2 d-flex flex-column justify-center align-items-center">
      <v-icon style="font-size: 3rem;">mdi-cart</v-icon>
      <p style="line-height: 1.0" class="display-1">Tu carrito está vacio</p>
      <small style="line-height: 1.0" class="text-center">
        Actualmente no hay productos en su carrito. Puede seguir comprando y agregar los productos deseados.
      </small>
    </div>

    <!-- Mostrar productos si el carrito tiene datos -->
    <div v-if="tieneProductos" class="bg-secondary pa-0 ma-0" style="overflow-y:scroll;">
      <div v-for="(item, index) in carrito.data" :key="index" class="py-2 mx-1">
        <div style="background-color: #fff;font-size: 12px;" class="py-2">
          <v-row class="py-0">
            <v-col cols="12" md="12" class="py-0 pt-2 pl-4">{{ item.ItemName }}</v-col>
            <v-col cols="12" md="12" class="py-0 pl-4">
              <span class="row-producto-item-code">{{ item.ItemCode }}</span>
              <span class="custom-badge">{{ item.db.substring(4, 6) }}</span>
            </v-col>
          </v-row>
          <v-row class="pb-2">
            <v-col cols="12" md="6" class="py-0 pl-4">{{ item.unidades }}</v-col>
            <v-col cols="12" md="6" class="py-0 text-end pr-4">
              {{"Subtotal: $" + $formatMoney(item.priceList.Price * item.unidades)}}
            </v-col>
          </v-row>
        </div>
      </div>

      <div v-if="$store.state.user.carrito.data.length != 0"  style="background-color: #fff;" class="carrito__div  pa-0 ma-0">
        <hr class="ml-5 mb-3" />
        <div class="div_total ml-2 mt-2">
          <router-link :to="routeDetalle"
          >
            <BaseButton
              color="btnPrimary"
              size="small"
            >
              Continuar
            </BaseButton>
          </router-link>
          <BaseButton
              color="btnPrimary"
              size="small"
              @click.prevent="vaciarCarrito"
              >
              Vaciar
            </BaseButton>
          <p v-if="modSaldo" class=" py-4 my-0 text-end">
            <b>Total: $ {{ $store.state.user.carrito.total ? $formatMoney($store.state.user.carrito.total) : "" }}</b>
          </p>

        </div>
      </div>
    </div>
  </v-col>
</template>


<script>
import { mapState } from "vuex";
import { BaseButton } from "../../../../components/design-system";

export default {
  components: {
    BaseButton,
  },
  data() {
    return {
      routeDetalle: "",
      modSaldo: true,
      totalSCC: 0,
    };
  },

  computed: {
    // Mapea el estado de Vuex a propiedades computadas
    ...mapState({
      carrito: state => state.user.carrito,  // Accede directamente al carrito
    }),

    // Total calculado
    totalCarrito() {
      return this.$store.state.user.carrito.total ? this.$formatMoney(this.carrito.total) : "";
    },

    tieneProductos() {
      return this.$store.state.user.carrito.data.length > 0; // Determina si hay productos en el carrito
    }

  },

  methods: {
    // Métodos relacionados al carrito
    vaciarCarrito(){
      this.$store.commit("CARRITO_REMOVE");
      this.$forceUpdate();

    }
  },

  mounted() {
    // Lógica de montaje si es necesario
  },
};
</script>
