<template>
  <v-container
    v-if="$isEnabledProperty($COMPRAR_ELEMENTOS_REGISTRALES)"
    id="crud"
    fluid
    tag="section"
    class="px-6"
    >
    <!--<v-card>
			<v-toolbar flat color="white">
				<v-toolbar-title>Administración de {{ title }}</v-toolbar-title>
			</v-toolbar> </v-card
		>-->
    <v-row
      ><v-col cols="auto" md="12" class="">
        <v-data-table
          :headers="headers"
          :items="desserts"
          :search="search"
          sort-by="secret"
          class="elevation-0 data__table"
          :footer-props="{
            'items-per-page-options': [10, 20],
          }"
        >
          <template v-slot:top>
            <v-toolbar flat color="white" class="pa-0 pr-0">
              <v-row class="pa-0 pt-4 ml-0">
                <v-col sm="6" class="px-0 ml-0">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscador"
                    dense
                    filled
                    rounded
                    solo
                  ></v-text-field>
                </v-col>

                <v-col sm="6" class="pa-0 pt-2 ml-0 text-end">
                  <span class="text--primary-cart"
                    >${{ $formatMoney($store.state.user.carrito.total) }}</span
                  >
                  <v-menu
                    bottom
                    right
                    offset-y
                    origin="top right"
                    transition="scale-transition"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ attrs, on }">
                      <v-btn
                        class="ml-2"
                        min-width="0"
                        text
                        v-bind="attrs"
                        v-on="on"
                        @click="force(item)"
                      >
                        <v-badge
                          v-if="$store.state.user.carrito.cantItem > 0"
                          color="primary"
                          overlap
                          bordered
                        >
                          <template v-slot:badge>
                            <span>{{
                              $store.state.user.carrito.cantItem
                            }}</span>
                          </template>

                          <v-icon class="icon-25">mdi-cart</v-icon>
                        </v-badge>
                        <v-badge v-else overlap bordered>
                          <v-icon>mdi-cart</v-icon>
                        </v-badge>
                      </v-btn>
                    </template>
                    <v-list
                      :tile="false"
                      class="pa-0 ma-0"
                      nav
                      dense
                      style="width: 320px"
                    >
                      <Carrito :key="keyCarrito" />
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-toolbar>
            <h2>Articulos</h2>
          </template>

          <template v-slot:header.unidades="{ header }">
            <div class="d-flex align-center">
              <span>{{ header.text }}</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    class="ml-2"
                    color="default"
                    style="
                      height: 18px;
                      width: 18px;
                      font-size: 10px;
                      background-color: black;
                    "
                  >
                    <i class="fa fa-info" aria-hidden="true"></i>
                  </v-btn>
                </template>
                <span>La cantidad debe ser multiplo de la cantidad mínima</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:[`item.ItemName`]="{ item }">
            <v-row>
              <v-col class="col-md-12 pt-4 pb-0 row-producto-item-name">
                {{ item.ItemName }}</v-col
              >
            </v-row>
            <v-row class="pt-2 mb-1">
              <v-col class="col-md-6 pa-0 px-3" style="margin-top: -10px">
                <v-row class="pt-2 mb-1">
                  <v-col class="col-md-2 pa-0 px-3" style="">
                    <span class="row-producto-item-code">{{
                      item.ItemCode
                    }}</span>
                  </v-col>

                  <v-col class="col-md-6 pa-0 px-3" style="">
                    <span
                      class="custom-badge"
                      :class="{
                        'badge-blue': item.db.substring(4, 6) === 'AC',
                        'badge-green': item.db.substring(4, 6) === 'MJ',
                      }"
                    >
                      {{ item.db.substring(4, 6) }}</span
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`item.priceList`]="{ item }">
            <v-row class="text-start">
              <v-col class="col-md-12 pt-2 pb-0"
                ><b>{{
                  item.priceList.Price != ""
                    ? "$ " + $formatMoney(item.priceList.Price)
                    : "&nbsp;"
                }}</b></v-col
              >
              <v-col class="col-md-12 pa-0 px-2" style="margin-top: -10px">
                <span class="row-unidades-compra-min"
                  >Compra min.
                  {{ Number(item.SalesQtyPerPackUnit) }} Unid.</span
                ></v-col
              >
            </v-row>
          </template>

          <template v-slot:[`item.unidades`]="{ item }">
            <div class="d-flex align-center">
              <v-btn
                x-small
                class="btn-decrement btn-mas-menos"
                @click="decrement(item)"
              >
                <v-icon style="font-size: 16px">mdi-minus</v-icon>
              </v-btn>

              <!-- Input de Unidades -->
              <v-text-field
                v-model.number="item.unidades"
                type="number"
                @keydown="validarSoloNumeros"
                class="row-unidades center-number-input no-border"
                hide-details
                height="25"
              ></v-text-field>

              <v-btn
                x-small
                class="btn-increment btn-mas-menos"
                @click="increment(item)"
              >
                <v-icon style="font-size: 16px">mdi-plus</v-icon>
              </v-btn>
            </div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              v-if="$isEnabledProperty($COMPRAR_ELEMENTOS_REGISTRALES)"
              small
              rounded
              :color="item.buttonColor || 'grey lighten-2'"
              class="px-3"
              @click="AgregarProductos(item)"
            >
              <small
                :class="
                  item.buttonColor
                    ? 'capitalize-first text-button-carrito'
                    : 'capitalize-first text-button-carrito-vacio'
                "
              >
                Agregar al carrito
              </small>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>
<style scoped>
.capitalize-first {
  text-transform: lowercase; /* Convierte todo el texto a minúsculas */
}

.capitalize-first::first-letter {
  text-transform: uppercase; /* Capitaliza solo la primera letra */
}
</style>
<script>
import { mapActions, mapMutations, mapState } from "vuex";

import Carrito from "@/views/dashboard/components/secundarios/Carrito.vue";
import AgregarProductos from "@/views/dashboard/components/secundarios/AgregarProductos.vue";
import Swal from "sweetalert2";
//import Carrito from '../dashboard/components/secundarios/Carrito.vue';

function title() {
  return "Elementos Registrales";
}

export default {
  components: {
    Carrito,
    AgregarProductos,
    //  Carrito,
  },

  data: (vm) => ({
    accionProducto: "",
    snackbar: false,
    text: "Registro Insertado",

    color: "success",
    timeout: 4000,
    title: title(),
    search: "",
    valid: true,

    keyCarrito: 0,
    dialog: false,
    readonly: true,
    readOnlySubtotal: true,
    headers: [
      { text: "Producto", filterable: true, value: "ItemName", width: "45%" },
      { text: "P. Unitario", filterable: true, value: "priceList" },
      {
        text: "Unidades",
        filterable: true,
        value: "unidades",
        sortable: false,
        width: "25%",
      },
      { text: "", value: "actions", sortable: false },
    ],

    route: "elementosRegistrales",

    desserts: [],
  }),

  created() {
    console.log(this.$store.state.user.carrito);
    this.getElementos();
  },

  mounted() {
    console.log("Componente " + this.baseUrl + " creado");
    this.$store.commit("SET_CART_EDIT_PATH", "/elementos_registrales");
    //this.fetchProductos();
    this.resetSourceVentaElementosAduana();
  },

  computed: {
    ...mapState("carrito", ["carrito", "total"]),
  },

  methods: {
    getElementos() {
      console.log(this.$store.state.user.sapProperties);
      let userProps = this.$store.state.user.sapProperties;
      let formSap = {};
      const trueProps = Object.entries(userProps)
        .filter(([key, value]) => value === true)
        .map(([key]) => key);
      //console.log(trueProps);
      trueProps.push(this.$store.state.user.sapProperties.PriceListNum);
      formSap.userProps = trueProps;
      Swal.alertGetInfo("Buscando información");
      this.$axiosApi
        .post(this.route, formSap)
        .then((r) => {
          if (r.data.data) {
            this.desserts = r.data.data;
          }

          Swal.close();
        })
        .catch(function (error) {
          console.log(error);
          this.snackbar = true;
          this.text = "Error al obtener datos. Error: " + error;
          this.color = "error";

          Swal.close();
        });
    },

    ...mapMutations("carrito", ["editProducto"]),
    ...mapMutations("aduana", ["resetSourceVentaElementosAduana"]),

    AgregarProductos(item) {
      // Usa una mutation para agregar o actualizar el item en el carrito
      if (item.unidades > 0) {
        this.$store.commit("CARRITO_ADD_OR_UPDATE_ITEM", item);

        this.keyCarrito++;
        // Cambiar color y etiqueta del botón
        item.buttonColor = "primary";

        this.$forceUpdate();
      } else {
        Swal.alert(
          "No es posible agregar el producto",
          "Para agregar el producto tenes que ingresar la cantidad de unididades"
        );
      }
    },
    force() {
      this.$forceUpdate();
      console.log(this.$store.state.user.carrito);
    },
    editProductos(item) {},

    increment(item) {
      // Buscar el índice del producto en el array 'desserts' por 'ItemCode'
      let index = this.desserts.findIndex((p) => p.ItemCode === item.ItemCode);

      if (index !== -1) {
        // Actualizar las unidades del producto
        this.desserts[index].unidades =
          this.desserts[index].unidades == "" ||
          isNaN(this.desserts[index].unidades)
            ? 0
            : this.desserts[index].unidades;
        this.desserts[index].unidades += Number(
          this.desserts[index].SalesQtyPerPackUnit
        );

        // Forzar la reactividad reemplazando el objeto
        this.$set(this.desserts, index, { ...this.desserts[index] });

        console.log(this.desserts[index].unidades); // Muestra la cantidad actualizada
      }
    },
    decrement(item) {
      // Buscar el índice del producto en el array 'desserts' por 'ItemCode'
      let index = this.desserts.findIndex((p) => p.ItemCode === item.ItemCode);

      if (index !== -1) {
        // Actualizar las unidades del producto
        if (
          this.desserts[index].unidades -
            Number(this.desserts[index].SalesQtyPerPackUnit) >=
          0
        ) {
          this.desserts[index].unidades -= Number(
            this.desserts[index].SalesQtyPerPackUnit
          );
        } else {
          this.desserts[index].unidades = 0;
        }

        // Forzar la reactividad reemplazando el objeto
        this.$set(this.desserts, index, { ...this.desserts[index] });

        console.log(this.desserts[index].unidades); // Muestra la cantidad actualizada
      }
    },
    validarSoloNumeros(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      const teclasEspeciales = [8, 9, 37, 39, 46]; // Retroceso, tab, flechas y borrar

      // Solo se permiten números (0-9) del teclado principal y numérico
      const esNumero =
        (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105);

      // Permitir teclas especiales (retroceso, borrar, flechas)
      const esTeclaEspecial = teclasEspeciales.includes(keyCode);

      // Bloquea caracteres especiales como `°!"#$%&/()=?¡{}][*+´¨`
      const esCaracterEspecial = keyCode >= 186 && keyCode <= 222;

      // Si no es número, tecla especial, o es un carácter especial, prevenir la acción
      if ((!esNumero && !esTeclaEspecial) || esCaracterEspecial) {
        event.preventDefault();
      }
    },
  },
  watch: {},
};
</script>
